import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import { ACCORDION_NAME, ACCORDION_NAME_TO_INDEX } from '../../../../components/LEAccordion/utils';
import Modal from '../../../../components/Modal';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { OtherLienholder } from '../../../../constants/lienholders';
import { TemporaryInfo, useTemporaryInfoUpdateMutation } from '../../../../gql/generated/graphql';
import { useTempInfo } from '../../../../hooks/useTempInfo';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
}

export const checkLienholderDifference = (
  info: TemporaryInfo,
  lienholder: string,
  other_lienholder: string,
) => {
  if (!info.data.lienholder) return false;
  return (
    lienholder !== info.data.lienholder ||
    (lienholder === OtherLienholder && other_lienholder !== info.data.other_lienholder)
  );
};

const ChangedLienholderModal = ({ isOpen, closeModal, handleSubmit }: Props) => {
  const { info } = useTempInfo('network-only', isOpen);
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const clearLienholder = async () => {
    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            vehicle_payoff: null,
            sales_tax_from_payoff: null,
            good_through_date: null,
            payoff_includes_sales_tax: null,
            sales_tax_from_payoff_entered_manually: null,
            current_accordion_panel: `${
              ACCORDION_NAME_TO_INDEX[ACCORDION_NAME.VEHICLE_ACCORDION]
            }:2`,
          },
        },
      },
    });
  };

  const changeMyLienholder = async () => {
    await clearLienholder();
    handleSubmit();
  };

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      title={<>Heads Up!</>}
      size={{ base: 'md', md: '3xl' }}
    >
      <Box textAlign="center">
        <Text color="leaseEndBlue" mb={6} textAlign="center" fontSize={{ base: 'lg', md: 'xl' }}>
          If you change your lienholder, any payoff or good through date information you had saved
          will be deleted. That's because every lienholder has different directions for obtaining
          these values.
          <br />
          <br />
          If you have any questions about the process, please contact us for help.
        </Text>
        <PrimaryButton mt={4} onClick={changeMyLienholder}>
          CHANGE MY LIENHOLDER
        </PrimaryButton>
      </Box>
    </Modal>
  );
};

export default ChangedLienholderModal;
