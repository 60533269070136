import { StackProps, Text } from '@chakra-ui/react';
import React from 'react';

import TextWithTooltip from '../../components/TextWithTooltip';
import YellowErrorContainer from '../../components/YellowContainer';

const tooltips = {
  payoffAmount: {
    text: 'Payoff Amount?',
    label: (
      <Text>
        <Text as="b">Payoff Amount</Text> includes the residual value of the car when the lease term
        began, the amount of payments remaining including interest, and a car purchase fee
        (depending on the company).
      </Text>
    ),
  },
  residualValue: {
    text: 'Residual Value',
    label: (
      <Text>
        The <Text as="b">Residual Value</Text> is set at the start of your lease by the leasing
        company, which may be the car dealership or another financier. It's the anticipated value of
        the car at the end of the lease and is used to determine your monthly lease payments.
      </Text>
    ),
  },
  amountYouStillOwe: {
    text: 'Amount You Still Owe',
    label: (
      <Text>
        The <Text as="b">Amount You Still Owe</Text> on your lease is he sum of the remaining
        payments you have on your lease, including any interest.
      </Text>
    ),
  },
};

const YellowBox: React.FC = ({ ...props }: StackProps) => {
  return (
    <YellowErrorContainer {...props}>
      <Text color="leaseEndBlue" textAlign="center">
        <Text as="span" fontWeight="bold">
          What's a{' '}
          <TextWithTooltip
            textProps={{
              color: 'leaseEndBlue',
              fontWeight: 'bold',
            }}
            label={tooltips.payoffAmount.label}
          >
            {tooltips.payoffAmount.text}
          </TextWithTooltip>{' '}
        </Text>{' '}
        This is your vehicle’s{' '}
        <TextWithTooltip
          textProps={{
            color: 'leaseEndBlue',
            fontWeight: 'bold',
          }}
          label={tooltips.residualValue.label}
        >
          {tooltips.residualValue.text}
        </TextWithTooltip>{' '}
        in addition to the{' '}
        <TextWithTooltip
          textProps={{
            color: 'leaseEndBlue',
            fontWeight: 'bold',
          }}
          label={tooltips.amountYouStillOwe.label}
        >
          {tooltips.amountYouStillOwe.text}
        </TextWithTooltip>
        , including interest.
      </Text>
    </YellowErrorContainer>
  );
};

export default YellowBox;
