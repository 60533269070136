import { useLocation } from 'react-router-dom';

import Loader from '../../components/Loader';
import { LDFlags } from '../../constants/flags';
import { useGetApprovedCreditDecisionsByDealIdQuery } from '../../gql/generated/graphql';
import { useFlag } from '../../hooks';
import Confetti from './Confetti';
import FrozenOrLockedCredit from './FrozenOrLockedCredit';
import NicelyDone from './NicelyDone';

const END_OF_FLOW_APPROVAL_VARIATIONS = {
  nicelyDone: 0,
  confetti: 1,
};

interface LocationState {
  isFailed?: boolean;
  dealId?: number | string;
  payoffFailure?: boolean;
  customerFrozenOrLockedCredit?: boolean;
  cobuyerFrozenOrLockedCredit?: boolean;
}

const AllDone = () => {
  const endOfFlowApprovalVariation = useFlag(LDFlags.END_OF_FLOW_APPROVAL_VARIATION);
  const confettiVariationActive =
    endOfFlowApprovalVariation === END_OF_FLOW_APPROVAL_VARIATIONS.confetti;
  const { state: locationState } = useLocation() as { state: LocationState };

  const dealId = locationState?.dealId ?? '';
  const creditFrozen =
    locationState?.customerFrozenOrLockedCredit || locationState?.cobuyerFrozenOrLockedCredit;
  const showConfetti =
    // need to compare with false because can be undefined
    locationState?.isFailed === false && confettiVariationActive && dealId && !creditFrozen;

  const { data: banksData, loading: creditDecisionsLoading } =
    useGetApprovedCreditDecisionsByDealIdQuery({
      skip: !showConfetti,
      variables: { dealId },
    });

  const banks =
    banksData?.getApprovedCreditDecisionsByDealId
      ?.filter((decision) => decision?.bank)
      ?.map((decision) => ({
        id: decision?.id || '',
        bank_logo: decision?.bank?.logo_url || '',
      })) || [];

  const loading = creditDecisionsLoading;

  if (loading) {
    return <Loader />;
  }

  if (creditFrozen) {
    return (
      <FrozenOrLockedCredit
        customerFrozenOrLockedCredit={locationState?.customerFrozenOrLockedCredit || false}
        cobuyerFrozenOrLockedCredit={locationState?.cobuyerFrozenOrLockedCredit || false}
      />
    );
  }

  if (showConfetti && !loading && banks.length > 0) {
    return <Confetti banks={banks} />;
  }

  return <NicelyDone payoffFailure={locationState?.payoffFailure} />;
};

export default AllDone;
