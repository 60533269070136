import { Button, Flex, SimpleGrid, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import Loader from '../../../../components/Loader';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { OtherLienholder, OtherLienholderSlug } from '../../../../constants/lienholders';
import { UNABLE_TO_ASSIST_ERROR_MSG } from '../../../../constants/tempInfos';
import { useTemporaryInfoUpdateMutation } from '../../../../gql/generated/graphql';
import { Lienholder } from '../../../../gql/prs/types';
import { useStep } from '../../../../hooks';
import { CookieKeys, useCookie } from '../../../../hooks/useCookie';
import useImageLoader from '../../../../hooks/useImageLoader';
import useLienholders from '../../../../hooks/useLienholders';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import { RudderEvent, rudderanalytics } from '../../../../utils/rudderstack';
import ChangedLienholderModal, { checkLienholderDifference } from './ChangedLienholderModal';
import LienholderModal from './LienholderModal';
import LienholderSelector from './LienholderSelector';
import { lienholderNotAvailableInState } from './utils';

interface FormValues {
  lienholder: string;
  other_lienholder: string;
}

const WhoIsYourLease = () => {
  const { state, updateState, index, setOpenAccordionIndex, isExpanded } =
    useLEAccordionItemContext();
  const [refresh, setRefresh] = useState<boolean>(false);
  const { info, infoLoading } = useTempInfo('network-only', refresh && isExpanded);
  const tempInfoData = info?.data;
  const ldClient = useLDClient();
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;
  const step = useStep();

  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  useEffect(() => {
    if (state.index === index) {
      ldClient?.track('Lienholder-Panel-Viewed', {});
    }
  }, [ldClient, state]);

  const {
    lienholders,
    probableLienholders,
    loading: lienholdersLoading,
  } = useLienholders({
    state: tempInfoData?.state,
    make: tempInfoData?.make,
  });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const probableLienholdersDisplayNames = probableLienholders
    .map((pLh: Lienholder) => pLh.display_name)
    .sort();

  const resolveNextStep = () => {
    updateState({ status: 'complete' });
    if (isAuthenticated) {
      step.moveNext();
    } else {
      setOpenAccordionIndex(index + 1);
    }
  };

  const {
    isOpen: isDifferentLienholderModalOpen,
    onClose: onDifferentLienholderModalClose,
    onOpen: onDifferentLienholderModalOpen,
  } = useDisclosure();

  const onSubmit = async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    const selectedLh = lienholders.find((lh) => lh.display_name === values.lienholder);
    const isVin = !tempInfoData?.license_plate_number;

    if (
      lienholderNotAvailableInState(
        isVin ? null : tempInfoData?.license_plate_state,
        selectedLh?.slug,
      )
    ) {
      formikHelpers.setFieldError('lienholder', UNABLE_TO_ASSIST_ERROR_MSG);
      setIsModalOpen(false);
      return;
    }

    if (isDifferentLienholderModalOpen) {
      onDifferentLienholderModalClose();
    } else if (
      info &&
      checkLienholderDifference(info, values.lienholder, values.other_lienholder)
    ) {
      onDifferentLienholderModalOpen();
      return;
    }

    const selectedLienholder = lienholders.find(
      (lh: Lienholder) => lh.display_name === values.lienholder,
    );
    const lienholderLogo = selectedLienholder?.logo_url ?? '';
    const lienholderSlug =
      values.lienholder === OtherLienholder ? OtherLienholderSlug : selectedLienholder?.slug ?? '';

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info?.id,
          data: {
            lienholder: values.lienholder,
            lienholder_slug: lienholderSlug,
            other_lienholder: values.lienholder === OtherLienholder ? values.other_lienholder : '',
            lienholderLogo,
          },
        },
      },
    });

    setIsModalOpen(false);
    resolveNextStep();
  };

  const validationSchema = Yup.object({
    lienholder: Yup.string().required('Please select a lienholder'),
    other_lienholder: Yup.string().when('lienholder', {
      is: OtherLienholder,
      then: (schema) => schema.required('Please enter lienholder name'),
    }),
  });

  useEffect(() => {
    if (isExpanded && (!tempInfoData?.state || !tempInfoData?.lienholder)) {
      setRefresh(!refresh);
    }
  }, [isExpanded, info]);

  const urls = useMemo(() => probableLienholders.map((lh) => lh.logo_url), [probableLienholders]);
  const loadingImages = useImageLoader(urls);

  if (lienholdersLoading || infoLoading || loadingImages) {
    return <Loader />;
  }

  return (
    <VStack fontSize={16} color="leaseEndBlue" mt={2} textAlign="center">
      <Formik
        initialValues={{
          lienholder: tempInfoData?.lienholder ?? '',
          other_lienholder: tempInfoData?.other_lienholder ?? '',
        }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting, handleSubmit, errors }) => {
          return (
            <Form>
              <Flex direction="column" alignItems="center">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing="22px">
                  {probableLienholders.map((probableLh) => (
                    <LienholderSelector
                      key={probableLh?.display_name}
                      lienholder={probableLh?.display_name}
                      lienholderLogo={probableLh?.logo_url}
                    />
                  ))}
                  {tempInfoData?.lienholder &&
                    !probableLienholdersDisplayNames?.includes(tempInfoData.lienholder) && (
                      <LienholderSelector
                        key={tempInfoData.lienholder}
                        lienholder={tempInfoData.lienholder}
                        lienholderLogo={
                          lienholders.find(
                            (lh: Lienholder) => lh.display_name === tempInfoData.lienholder,
                          )?.logo_url
                        }
                        otherLienholder={tempInfoData.other_lienholder}
                      />
                    )}
                </SimpleGrid>
                {errors.lienholder && (
                  <Text color="red" fontSize="14px" mt={5}>
                    {errors.lienholder}
                  </Text>
                )}
                <Button
                  variant="link"
                  onClick={() => {
                    rudderanalytics.track(RudderEvent.OtherLienholderModalOpen);
                    setIsModalOpen(true);
                  }}
                  mt="20px"
                >
                  See more options
                </Button>
                <PrimaryButton
                  w="fit-content"
                  type="submit"
                  loading={isSubmitting}
                  disabled={infoLoading}
                  mt="30px"
                />
              </Flex>
              <LienholderModal
                info={info}
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                lienholders={lienholders}
                handleSubmit={handleSubmit}
              />
              <ChangedLienholderModal
                isOpen={isDifferentLienholderModalOpen}
                closeModal={onDifferentLienholderModalClose}
                handleSubmit={handleSubmit}
              />
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default WhoIsYourLease;
