import {
  ApolloClient,
  ApolloError,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ServerError } from '@apollo/client/link/utils';
import { createStandaloneToast } from '@chakra-ui/react';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { OperationDefinitionNode } from 'graphql';
import { createClient } from 'graphql-ws';
import React, { ReactNode, useEffect, useState } from 'react';

import customTheme from '../chakra';
import config from '../config';
import { PAYOFF_INSTRUCTIONS } from '../constants/urls';
import { linkAccount } from '../gql/customerGql';
import { totalCompletedDeals } from '../gql/dealGql';
import { ErrorCodeEnum } from '../gql/generated/graphql';
import { paymentEstimate } from '../gql/paymentEstimateGql';
import { uploadDocument, uploadDriversLicense } from '../gql/tatertitleGql';
import { CookieKeys, getCookie, useCookie } from '../hooks/useCookie';
import { logout } from '../services/auth0';
import { handleErrorWithSentry } from '../services/sentry';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import { createErrorToast } from '../utils/toast';

const joinStrings = (...strings: string[]) => strings.filter(Boolean).join(' ');
const displayIfExists = (label: string, value: unknown) => (value ? `${label}: ${value}.` : '');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getErrorsFromResult = (result: any) => {
  if (result && result.errors) {
    return result.errors.map((error: ApolloError) => error?.message).join(', ');
  }
  return '';
};

const HIDDEN_ERROR_MESSAGES = ['SSN does not match', 'Socket closed', 'Max attempt limit reached.'];

const GraphQLEndpoints = {
  LE: 'le',
  PRS: 'prs',
} as const;

const allowedErrorPathsAndOperations: (string | number)[] = [
  ...[linkAccount, totalCompletedDeals, uploadDocument, uploadDriversLicense, paymentEstimate].map(
    (operation) =>
      (operation?.definitions[0] as OperationDefinitionNode)?.name?.value as string | number,
  ),
  ...[PAYOFF_INSTRUCTIONS],
];

const allowErrors = (path: readonly (string | number)[] | undefined): boolean | undefined =>
  path?.some((p) => allowedErrorPathsAndOperations.includes(p));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {},
    },
    deals: {
      keyFields: ['id', 'isCobuyer'],
    },
  },
});

const { toast } = createStandaloneToast({ theme: customTheme });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const operationName = operation?.operationName;
  const { pathname } = window.location;

  const { isErrorHandled } = operation.getContext();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path, extensions }) => {
      handleErrorWithSentry(
        `[GraphQL error]: Message: ${message}, Operation: ${operationName}, Path: ${path}, Pathname: ${pathname}, Extensions: ${JSON.stringify(
          extensions,
        )}${isErrorHandled ? ' (handled)' : ''}`,
      );
      rudderanalytics.track(RudderEvent.Error, {
        error_message: message,
        pathname,
        operationName,
        isErrorHandled,
      });

      if (extensions && extensions.code === ErrorCodeEnum.Unauthenticated) {
        logout();
      } else if (
        !allowErrors(path) &&
        !isErrorHandled &&
        !HIDDEN_ERROR_MESSAGES.some((hiddenMessage) => message.includes(hiddenMessage))
      ) {
        toast(createErrorToast({ errorMessage: message, logError: false }));
      }
    });
  }

  if (networkError) {
    const { name, message, statusCode, result, response } = networkError as ServerError;

    handleErrorWithSentry(
      joinStrings(
        `[Network error] ${name} (${statusCode}):`,
        displayIfExists('Message', message),
        displayIfExists('Operation', operationName),
        displayIfExists('Result', getErrorsFromResult(result)),
        displayIfExists('ResponseText', response?.statusText),
        displayIfExists('Path', window.location.host + window.location.pathname),
        isErrorHandled ? ' (handled)' : '',
      ),
      networkError.stack,
    );
    rudderanalytics.track(RudderEvent.Error, {
      error_message: message,
      pathname,
      operationName,
      statusCode,
      responseText: response?.statusText,
      isErrorHandled,
    });

    if ((networkError as ServerError).statusCode === 401) {
      logout();
    } else if (
      !allowErrors([window.location.pathname]) &&
      !isErrorHandled &&
      !HIDDEN_ERROR_MESSAGES.includes(message)
    ) {
      toast(createErrorToast({ errorMessage: message, logError: false }));
    }
  }
});

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      const shouldRetry =
        !!error &&
        (error.statusCode === undefined || // Pure network errors
          error.statusCode === 408 || // Request Timeout
          error.statusCode === 502 || // Bad Gateway
          error.statusCode === 503 || // Service Unavailable
          error.statusCode === 504); // Gateway Timeout

      if (shouldRetry) {
        rudderanalytics.track(RudderEvent.ErrorRetry, {
          error_message: error.message,
          operation_name: operation.operationName,
          status_code: error.statusCode,
        });
      }

      return shouldRetry;
    },
  },
  delay: {
    initial: 1000,
    max: 5000,
    jitter: true,
  },
});

const getAuthHeader = () => {
  const accessToken = getCookie<string>(CookieKeys.ACCESS_TOKEN);
  const guid = getCookie<string>(CookieKeys.GUID_KEY);
  return {
    ...(accessToken && {
      authorization: `Bearer ${accessToken}`,
    }),
    ...(guid && {
      guid,
    }),
  };
};

export const ResetWsContext = React.createContext<{ resetWebSocket: () => void }>({
  resetWebSocket: () => undefined,
});

const apolloClient = new ApolloClient({
  cache,
  connectToDevTools: true,
});

const removeTypenameLink = removeTypenameFromVariables();

const AuthorizedApolloProvider = ({ children }: { children: ReactNode }) => {
  const [guid] = useCookie<string>(CookieKeys.GUID_KEY);
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const [resetWebSocket, setResetWebSocket] = useState<() => void>();

  useEffect(() => {
    const ws = new GraphQLWsLink(
      createClient({
        url: `${config.urls.wsRoot}/graphql`,
        connectionParams: async () => {
          return getAuthHeader();
        },
      }),
    );

    const multiApiLink = new MultiAPILink({
      endpoints: {
        [GraphQLEndpoints.LE]: config.urls.apiRoot,
        [GraphQLEndpoints.PRS]: config.urls.prsApiRoot,
      },
      createWsLink: () => ws,
      defaultEndpoint: GraphQLEndpoints.LE,
      getContext: (_, getCurrentContext) => ({
        headers: { ...getCurrentContext().headers, ...getAuthHeader() },
      }),
      createHttpLink: () => createHttpLink(),
    });

    apolloClient.setLink(
      errorLink.concat(retryLink.concat(removeTypenameLink.concat(multiApiLink))),
    );
    setResetWebSocket(() => () => ws.client.terminate());
  }, [guid, accessToken]);

  return apolloClient && resetWebSocket ? (
    <ApolloProvider client={apolloClient}>
      <ResetWsContext.Provider value={{ resetWebSocket }}>{children}</ResetWsContext.Provider>
    </ApolloProvider>
  ) : (
    <></>
  );
};

export default AuthorizedApolloProvider;
export { apolloClient };
