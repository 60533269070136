import * as Yup from 'yup';

import { OWNERSHIP_TYPE } from '../../constants/addresses';
import {
  EmploymentStatusType,
  MaritalStatusEnum,
  StateAbbreviation,
  TemporaryData,
} from '../../gql/generated/graphql';
import { loanAppSsnFullValidation } from '../../utils/validation/SSNumber';
import { movedStatesValidation } from '../../utils/validation/address';
import {
  customerAddressValidation,
  customerEmploymentValidation,
  customerPersonalInfoValidation,
  customerPreviousEmploymentValidation,
  customerPreviousResidenceValidation,
  customerPrimaryResidenceValidation,
} from '../../utils/validation/customerInformation';
import { dobValidation } from '../../utils/validation/dob';
import { maritalStatusValidation } from '../../utils/validation/maritalStatuses';
import { middleNameValidation } from '../../utils/validation/names';

export enum PayFrequency {
  Weekly = 'weekly',
  BiWeekly = 'biweekly',
  Monthly = 'monthly',
  Annually = 'annually',
}

export interface ResidenceFormValues {
  address_line: string;
  address_line_2: string;
  city: string;
  state: string;
  county: string;
  zip: string | number;
  moved_states: string;

  primary_residence_years: number | undefined | null;
  primary_residence_type: string | undefined | null;
  primary_residence_months: number | undefined | null;
  primary_residence_monthly_payment: number | undefined | null;
  previous_address_line: string | undefined | null;
  previous_address_line_2: string | undefined | null;
  previous_city: string | undefined | null;
  previous_state: string | undefined | null;
  previous_zip: string | undefined | null;
  previous_county: string | undefined | null;
  secondary_residence_years: number | undefined | null;
  secondary_residence_months: number | undefined | null;
}

export const residenceInitialValues = (tempInfoData: TemporaryData) => ({
  address_line: tempInfoData.address_line ?? '',
  address_line_2: tempInfoData.address_line_2 ?? '',
  city: tempInfoData.city ?? '',
  state: tempInfoData.state ?? '',
  zip: tempInfoData.zip ?? '',
  county: tempInfoData.county ?? '',
  moved_states: 'false',

  primary_residence_type:
    tempInfoData.primary_residence_type ?? OWNERSHIP_TYPE.CURRENTLY_HAVE_A_MORTGAGE,
  primary_residence_years: tempInfoData.primary_residence_years ?? undefined,
  primary_residence_months: tempInfoData.primary_residence_months ?? undefined,
  primary_residence_monthly_payment:
    tempInfoData.primary_residence_type !== OWNERSHIP_TYPE.OWN_HOME_OUTRIGHT
      ? tempInfoData.primary_residence_monthly_payment ?? undefined
      : undefined,
  secondary_residence_years: tempInfoData.secondary_residence_years ?? undefined,
  secondary_residence_months: tempInfoData.secondary_residence_months ?? undefined,
  previous_address_line: tempInfoData.previous_address_line,
  previous_address_line_2: tempInfoData.previous_address_line_2,
  previous_city: tempInfoData.previous_city,
  previous_state: tempInfoData.previous_state,
  previous_zip: tempInfoData.previous_zip ? tempInfoData.previous_zip.toString() : undefined,
  previous_county: tempInfoData.previous_county,
});

export const residenceValidationSchema = Yup.object({
  ...customerAddressValidation,
  moved_states: movedStatesValidation,
  ...customerPrimaryResidenceValidation,
  ...customerPreviousResidenceValidation,
});

export interface EmploymentFormValues {
  employment_status: string;
  employer_name: string;
  employer_phone_number: string;
  job_title: string;
  years_at_job: number | undefined | null;
  months_at_job: number | undefined | null;
  salary: number | undefined | null;
  pay_frequency: string;
  previous_employer_name: string;
  previous_employer_phone_number: string;
  previous_job_title: string;
  previous_years_at_job: number | undefined | null;
  previous_months_at_job: number | undefined | null;
}

export const employmentInitialValues = (tempInfoData: TemporaryData) => ({
  employment_status: tempInfoData.employment_status ?? EmploymentStatusType.Employed,
  employer_name: tempInfoData.employer_name ?? '',
  employer_phone_number: tempInfoData.employer_phone_number ?? '',
  job_title: tempInfoData.job_title ?? '',
  years_at_job: tempInfoData.years_at_job ?? undefined,
  months_at_job: tempInfoData.months_at_job ?? undefined,
  salary: tempInfoData.salary ?? undefined,
  pay_frequency: PayFrequency.Annually,
  previous_employer_name: tempInfoData.previous_employer_name ?? '',
  previous_employer_phone_number: tempInfoData.previous_employer_phone_number ?? '',
  previous_job_title: tempInfoData.previous_job_title ?? '',
  previous_years_at_job: tempInfoData.previous_years_at_job ?? undefined,
  previous_months_at_job: tempInfoData.previous_months_at_job ?? undefined,
});

export const employmentValidationSchema = Yup.object({
  ...customerEmploymentValidation,
  ...customerPreviousEmploymentValidation,
});

export interface SsnAndBirthdayValues {
  dob: Date | undefined;
  ssn: string;
  marital_status: MaritalStatusEnum | undefined;
  must_enter_ssn: boolean;
}

export const ssnAndBirthdayInitialValues = (tempInfoData: TemporaryData) => ({
  dob: tempInfoData.dob ? new Date(tempInfoData.dob) : undefined,
  ssn: '',
  marital_status: tempInfoData.marital_status
    ? tempInfoData.marital_status
    : tempInfoData.state === StateAbbreviation.Wi
    ? MaritalStatusEnum.NotMarried
    : undefined,
  must_enter_ssn: true,
});

export const ssnAndBirthdayValidationSchema = (fromWisconsin: boolean) =>
  Yup.object({
    dob: dobValidation,
    ssn: loanAppSsnFullValidation,
    marital_status: maritalStatusValidation(fromWisconsin),
    must_enter_ssn: Yup.boolean().required(),
  });

export interface VerifyPersonalInfoValues {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}

export const verifyPersonalInfoInitialValues = (
  tempInfoData?: TemporaryData,
  accessTokenEmail?: string,
) => ({
  first_name: tempInfoData?.first_name ?? '',
  middle_name: tempInfoData?.middle_name ?? '',
  last_name: tempInfoData?.last_name ?? '',
  email: tempInfoData?.email ?? accessTokenEmail ?? '',
  phone_number: tempInfoData?.phone_number ?? '',
});

export const verifyPersonalInfoValidationSchema = Yup.object({
  ...customerPersonalInfoValidation,
  middle_name: middleNameValidation,
});
