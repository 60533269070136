import { LDFlags } from '../../../../../constants/flags';
import { useFlag } from '../../../../../hooks';
import EndingInZeroMessage from './EndingInZeroMessage';
import OverMileageFeeWarning from './OverMileageFeeWarning';

interface Props {
  mileage: number;
  mileageWarnings: {
    endingInZero: boolean;
    overMileage: boolean;
  };
}

const MileageWarning = ({ mileage, mileageWarnings }: Props) => {
  const { endingInZero, overMileage } = mileageWarnings;
  const mileageWarningModalEnabled = useFlag(LDFlags.MILEAGE_WARNING_MODAL_VARIATION);

  const showOverMileageNotice = overMileage && !mileageWarningModalEnabled;

  if (!showOverMileageNotice && !endingInZero) {
    return null;
  }

  if (showOverMileageNotice) {
    return <OverMileageFeeWarning mileage={Number(mileage)} />;
  }

  return <>{endingInZero && <EndingInZeroMessage />}</>;
};

export default MileageWarning;
