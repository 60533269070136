/**
 * When adding or removing flags, update the following:
 * - LDFlags enum
 * - if flag is not a boolean, add/remove the flag to the <type>Flags array
 */

export enum LDFlags {
  // Active flags
  ATC_API = 'atc-api', // marketing mixed
  DOWN_PAYMENTS = 'down-payments', // Stripe, keeping for future initiative
  END_OF_FLOW_APPROVAL_VARIATION = 'end-of-flow-approval-variation',
  EXPERIAN_HARD_PULL = 'experian-hard-pull',
  FORCE_AFTER_HOURS = 'force-after-hours', // Forces after hours behavior. For testing, or holidays I guess.
  FLOW_SHOPPING_CART = 'flow-shopping-cart',
  KBB_OPTIONS_PAGE = 'kbb-options-page',
  RE_CAPTCHA_ENABLED = 're-captcha-enabled', // marketing mixed?
  SERVICE_DOWN_BANNER = 'service-down-banner',

  // Temporary flags - flags meant to be removed after a/b test or implementation
  AFTER_SIGNING_EXPERIENCE = 'after-signing-experience',
  BREAK_OUT_CREDIT_AND_DEBIT_CARDS = 'break-out-credit-and-debit-cards',
  CITY_AND_COUNTY_VALIDATION = 'city-and-county-validation',
  COM_DASHBOARD_DOWN_PAYMENT = 'com-dashboard-down-payment',
  COM_INCLUDE_SALES_TAX_ON_PAYOFF = 'com-include-sales-tax-on-payoff',
  CONTACT_INFO_VARIATION = 'contact-info-variation',
  CREDIT_PREQUALIFICATION = 'credit-prequalification',
  GETTING_STARTED_HEADER = 'getting-started-header',
  MID_FLOW_PAYMENT_CALC = 'mid-flow-payment-calculator-test',
  OVER_MILEAGE_SAVINGS = 'over-mileage-savings',
  PHONE_NUMBER_PLACEMENT_VARIATION = 'phone-number-placement-variation',
  PREQUAL_CONSENT_CHECKBOX = 'prequal-consent-checkbox',
  REMOVE_FLOW_PASSWORD = 'remove-flow-password',
  SHOW_EMAIL_NOTE = 'show-email-note',
  SHOW_FLOW_PROGRESS_METER = 'show-flow-progress-meter',
  SHOW_PAGE_PROGRESS_METER = 'show-page-progress-meter',
  SSN_DASH_VERIFICATION = 'ssn-dash-verification',
  STACKED_RADIO_BUTTONS = 'stacked-radio-buttons',
  SECURE_INFO_LINK = 'secure-info-link',
  SHOW_PURPLE_PEOPLE = 'show-purple-people',
  SHOW_SAFETY_MESSAGE = 'show-safety-message',
  ODOMETER_ZEROS_WARNING = 'odometer-zeros-warning',
  MILEAGE_WARNING_MODAL_VARIATION = 'mileage-warning-modal-variation',
  HIDE_GOOD_THROUGH_DATE = 'hide-good-through-date',
}

// Flag evaluations we don't want tracked in Rudderstack
export const FLAG_TRACKING_BLACK_LIST = [LDFlags.ATC_API, LDFlags.RE_CAPTCHA_ENABLED];

const stringFlags = [LDFlags.SERVICE_DOWN_BANNER] as const;
const numberFlags = [
  LDFlags.MID_FLOW_PAYMENT_CALC,
  LDFlags.PHONE_NUMBER_PLACEMENT_VARIATION,
] as const;

export type LDFlagValueTypes = {
  [key in LDFlags]: key extends typeof stringFlags[number]
    ? string
    : key extends typeof numberFlags[number]
    ? number
    : boolean;
};

export type FlagOverrides = {
  [key in LDFlags]?: unknown;
};
