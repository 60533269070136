import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { FormikValues } from 'formik';

import Modal from '../../components/Modal';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { formatMoney } from '../../utils/helpers';

interface Props {
  values: FormikValues;
  handleSubmit: (values: FormikValues, goodToGo?: boolean) => void;
  payoffAmount: number;
  onEstimatedModalClose: () => void;
  isEstimatedModalOpen: boolean;
}

const PayoffEstimateModal = ({
  handleSubmit,
  values,
  payoffAmount,
  onEstimatedModalClose,
  isEstimatedModalOpen,
}: Props) => {
  return (
    <Modal
      size={{ base: 'sm', lg: '2xl' }}
      onClose={onEstimatedModalClose}
      isOpen={isEstimatedModalOpen}
      title="Are you sure?"
      isCentered={false}
    >
      <VStack color="leaseEndBlue" spacing={4}>
        <Text fontSize="2xl">The payoff you entered was...</Text>
        <Text fontSize="4xl" fontWeight="semibold">
          {formatMoney(payoffAmount)}
        </Text>
        <Text fontSize={{ base: 'lg', md: 'xl' }} textAlign="center">
          Lease payoffs usually aren't exact figures. If you're certain this is correct, then we're
          good to go! Otherwise click <b>Change Amount</b> to adjust.
        </Text>
        <Box>
          <VStack mt={4} spacing={4}>
            <PrimaryButton onClick={onEstimatedModalClose}>CHANGE AMOUNT</PrimaryButton>
            <Button colorScheme="blue" variant="link" onClick={() => handleSubmit(values, true)}>
              Good to go
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Modal>
  );
};

export default PayoffEstimateModal;
