import { DealMediaTypeEnum } from '../gql/generated/graphql';
import { logger } from '../services/sentry';

export const MAX_FILE_SIZE_MB = 5;

export const ALLOWED_FILE_TYPES: string[] = ['image/png', 'image/jpg', 'image/jpeg'];
export const HEIC_FILE_TYPES: string[] = ['image/heic', 'image/heif'];
export const INPUT_FILE_TYPES: string[] = [...ALLOWED_FILE_TYPES, ...HEIC_FILE_TYPES];

export const convertHeic = async (file: File) => {
  if (!HEIC_FILE_TYPES.includes(file.type)) return file;

  try {
    const heic2any = (await import('heic2any')).default;
    let output = await heic2any({
      blob: file,
      toType: 'image/png',
    });

    if (Array.isArray(output)) {
      [output] = output;
    }

    const filename = `${file.name.substring(0, file.name.lastIndexOf('.'))}.png`;

    return new File([output], filename, { type: 'image/png' });
  } catch (e) {
    logger.error('utils/media.ts:convertHeic()', 'Failed converting to heic2any', file, e);
    throw Error('There was an error uploading your file.');
  }
};

export const getFilePathFromSignedUrl = (userImg: string) => {
  const splitMeta = userImg.split('?');
  const splits3 = splitMeta[0].split('com/');
  const filePath = splits3[1];
  return filePath;
};

export const convertFile = async (file?: File, allowPdf?: boolean) => {
  if (!file) {
    throw Error('There was an error uploading your file.');
  }
  const convertedFile = await convertHeic(file);
  const allowedFileTypes = [...ALLOWED_FILE_TYPES];
  if (allowPdf) {
    allowedFileTypes.push('application/pdf');
  }

  if (
    !allowedFileTypes.includes(convertedFile.type) ||
    (!!file.size && file.size / 1000000 > MAX_FILE_SIZE_MB)
  ) {
    throw Error(
      `Files cannot exceed ${MAX_FILE_SIZE_MB}MB and must be a valid format (${INPUT_FILE_TYPES.map(
        (type) => type.split('/')[1].toUpperCase(),
      ).join(', ')}).`,
    );
  }

  return convertedFile;
};

export const generateFileName = (mediaType: DealMediaTypeEnum, filename: string) => {
  const splitFilename = filename.split('.');
  const filetype = splitFilename.length > 1 ? splitFilename[splitFilename.length - 1] : '';

  const now = new Date();
  const timestamp = now
    .toLocaleString('en-US', {
      timeZone: 'America/Denver',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23',
    })
    .replaceAll('/', '-')
    .replaceAll(',', '')
    .replaceAll(' ', '_')
    .replaceAll(':', '-');

  const newFilename = `${mediaType}_${timestamp}.${filetype}`.toLowerCase();
  return newFilename;
};
