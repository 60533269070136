import { Box, BoxProps, FormLabelProps, Link, TextProps } from '@chakra-ui/react';
import React from 'react';

import Description from '../../Description';
import Label from '../../formComponents/Label';

export const InstructionsContainer: React.FC<BoxProps> = ({ children, ...props }) => (
  <Box h="auto" textAlign="left" m={{ md: '0 auto' }} {...props}>
    {children}
  </Box>
);

export const InstructionsLabel: React.FC<FormLabelProps> = ({ children, ...props }) => (
  <Label fontSize={{ md: '20px' }} {...props}>
    {children}
  </Label>
);

export const InstructionsDescription: React.FC<TextProps> = ({ children, ...props }) => (
  <Description fontSize={{ base: '14px', md: '16px' }} {...props}>
    {children}
  </Description>
);

export const WebsiteLabel = ({ website }: { website: string }) => {
  return (
    <Label fontSize={{ md: '16px' }}>
      Get your Payoff Amount and good-through date online{' '}
      <Link color="royalBlue" _hover={{ color: 'royalBlueDark' }} href={website} target="_blank">
        here
      </Link>
      .
    </Label>
  );
};

export const PhoneLabel = ({ phones, hasBoth }: { phones: string[]; hasBoth?: boolean }) => {
  return (
    <Label fontSize={{ md: '16px' }}>
      {hasBoth ? 'Or call ' : 'Call '}
      {phones.map((phone) => (
        <Link
          color="royalBlue"
          _hover={{ color: 'royalBlueDark' }}
          href={`tel: ${phone}`}
          key={`phone-${phone}`}
          mr="2"
          _last={{ mr: 0 }}
        >
          {phone}
        </Link>
      ))}
      {!hasBoth && ' for your Payoff Amount and good-through date'}.
    </Label>
  );
};
