import { VStack } from '@chakra-ui/react';
import React from 'react';

import Modal from '../../../../../components/Modal';
import PrimaryButton from '../../../../../components/ui/buttons/PrimaryButton';
import OverMileageFeeWarning from './OverMileageFeeWarning';

interface Props {
  mileage: number;
  isOpen: boolean;
  onClose: () => void;
}

const MileageWarningModal = ({ mileage, isOpen, onClose }: Props) => (
  <Modal
    title="Dealership Fee Alert!"
    isOpen={isOpen}
    onClose={onClose}
    blockScrollOnMount={false}
    isCentered
    closeOnOverlayClick={false}
  >
    <VStack m={0} p={0}>
      <OverMileageFeeWarning isModal mileage={mileage} />
      <PrimaryButton mt={4} onClick={onClose}>
        CLOSE
      </PrimaryButton>
    </VStack>
  </Modal>
);

export default MileageWarningModal;
