import Big from 'big.js';
import * as Yup from 'yup';

import { DEFAULT_DOC_FEE } from '../../constants/paymentCalculator';
import {
  StateAbbreviation,
  TemporaryData,
  TtFuelType,
  TtGetFeesSourceType,
  useGetFeesLazyQuery,
} from '../../gql/generated/graphql';
import getFeesFromTT from '../../utils/fees';
import { payoffAmountValidation } from '../../utils/validation/payoffAmount';

export type PayoffValues = {
  totalPayoff?: number;
  vehiclePayoff?: number;
  payoffIncludesSalesTax?: boolean;
  salesTaxFromPayoff?: number;
  goodThroughDate?: Date;
};

const YESTERDAY = new Date(new Date().setDate(new Date().getDate() - 1));

export const validationSchema = Yup.object({
  totalPayoff: payoffAmountValidation,
  goodThroughDate: Yup.date()
    .min(YESTERDAY, 'Cannot be in the past')
    .nullable()
    .required('Please enter a valid date'),
});

export interface GetPayoffAndStateTaxesOpts {
  values: PayoffValues;
  tempInfoData: TemporaryData;
  getTTFeesLazyQuery: ReturnType<typeof useGetFeesLazyQuery>[0];
}

const getVehiclePayoffAndSalesTaxFromPayoff = (totalPayoff = 0, salesTaxRate = 0) => {
  const vehiclePayoff = new Big(totalPayoff)
    .div(1 + salesTaxRate)
    .round(2)
    .toNumber();

  const salesTaxFromPayoff = new Big(totalPayoff).minus(vehiclePayoff).round(2).toNumber();

  return { vehiclePayoff, salesTaxFromPayoff };
};

export const getPayoffAndStateTaxes = async ({
  values,
  tempInfoData,
  getTTFeesLazyQuery,
}: GetPayoffAndStateTaxesOpts): Promise<PayoffValues> => {
  if (!values.payoffIncludesSalesTax) {
    return { ...values, vehiclePayoff: values.totalPayoff, salesTaxFromPayoff: 0 };
  }

  if (values.payoffIncludesSalesTax && values.vehiclePayoff && values.salesTaxFromPayoff)
    return values;

  if (
    !values.totalPayoff ||
    !tempInfoData.zip ||
    !tempInfoData.vin ||
    !tempInfoData.fuel_type ||
    !tempInfoData.year ||
    !tempInfoData.retail_book_value ||
    !tempInfoData.vehicle_type ||
    !tempInfoData.city ||
    !tempInfoData.county ||
    !tempInfoData.state ||
    !tempInfoData.make ||
    !tempInfoData.model
  ) {
    return values;
  }
  const docFee = tempInfoData.doc_fee ?? DEFAULT_DOC_FEE;

  const fees = await getFeesFromTT(getTTFeesLazyQuery, {
    state: tempInfoData.state as StateAbbreviation,
    previouslyTitledState: (tempInfoData.previous_state ?? tempInfoData.state) as StateAbbreviation,
    city: tempInfoData.city,
    county: tempInfoData.county,
    zip: tempInfoData.zip,
    vin: tempInfoData.vin,
    make: tempInfoData.make ?? '',
    model: tempInfoData.model ?? '',
    year: tempInfoData.year,
    vehicleType: tempInfoData.vehicle_type ?? '',
    fuelType: tempInfoData.fuel_type as TtFuelType,
    firstName: tempInfoData.first_name ?? '',
    lastName: tempInfoData.last_name ?? '',
    retailBookValue: tempInfoData.retail_book_value ?? 0,
    bookValue: tempInfoData.book_value ?? 0,
    docFee,
    payoff: tempInfoData.vehicle_payoff ?? 0,
    estimatedPayoff: tempInfoData.shopping_cart?.buyout ?? 0,
    warranty: 0,
    source: TtGetFeesSourceType.ComEnterPayoff,
  });

  const { vehiclePayoff, salesTaxFromPayoff } = getVehiclePayoffAndSalesTaxFromPayoff(
    values.totalPayoff,
    fees.salesTaxRate,
  );

  return { ...values, vehiclePayoff, salesTaxFromPayoff };
};

export const isPayoffEstimated = (payoff: number) => {
  return payoff % 100 === 0;
};
