import { ListItem, OrderedList } from '@chakra-ui/react';

import { Instruction } from '../../../gql/prs/types';
import Description from '../../Description';

const Instructions = ({ instructions }: { instructions: Instruction[] }) => {
  return (
    <OrderedList mb={4}>
      {instructions.map(({ details, text }) => (
        <ListItem key={`instruction-${text.slice(10)}`} mt={2}>
          <Description fontSize={{ md: '16px' }}>{text}</Description>
          {details && (
            <OrderedList listStyleType="upper-alpha">
              {Array.isArray(details) ? (
                details.map((detail) => (
                  <ListItem>
                    <Description fontSize={{ md: '14px' }}>{detail}</Description>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <Description fontSize={{ md: '14px' }}>{details}</Description>
                </ListItem>
              )}
            </OrderedList>
          )}
        </ListItem>
      ))}
    </OrderedList>
  );
};

export default Instructions;
