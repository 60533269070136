import { Text } from '@chakra-ui/react';
import { atom, useAtomValue } from 'jotai';

import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import useEstimatorFormik from '../../components/shoppingCart/useEstimatorFormik';
import BackButton from '../../components/ui/buttons/BackButton';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import { useTempInfo } from '../../hooks';
import BuyerCoBuyerSwitch from '../LoanApp/components/BuyerCobuyerSwitch';
import LoanAppDevToolbar from '../LoanApp/components/LoanAppDevToolbar';
import CobuyerEmployment from './components/CobuyerEmployment';
import CobuyerPersonalInfo from './components/CobuyerPersonalInfo';
import CobuyerResidence from './components/CobuyerResidence';
import CobuyerSsnAndBirthdate from './components/CobuyerSsnAndBirthdate';

export const cobuyerFirstNameAtom = atom<string | null>(null);

const CobuyerLoanApp = () => {
  const { info } = useTempInfo();
  const tempInfoData = info?.data;

  const cobuyerFirstName =
    useAtomValue(cobuyerFirstNameAtom) ||
    tempInfoData?.cobuyer_first_name?.toUpperCase() ||
    'CO-BUYER';

  useEstimatorFormik();

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>Your Application</LEAccordionHeader>
      <Text textAlign="center" fontSize="16px" mb="5px">
        To include a co-buyer, we'll need some personal information about them to complete your
        application.
      </Text>
      <BuyerCoBuyerSwitch justifyContent="center" my={4} />
      <LEAccordion name={ACCORDION_NAME.COBUYER_LOAN_APP_ACCORDION} minutes={4}>
        <LEAccordionItem
          accordionKey="coBuyerLoanVerifyPersonalInfo"
          title={`${cobuyerFirstName}'S PERSONAL INFO`}
        >
          <CobuyerPersonalInfo />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanResidence"
          title={`${cobuyerFirstName}'S RESIDENTIAL INFO`}
        >
          <CobuyerResidence />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanEmployment"
          title={`${cobuyerFirstName}'S EMPLOYMENT INFO`}
        >
          <CobuyerEmployment />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="coBuyerLoanBirthdate"
          title={`${cobuyerFirstName}'S DATE OF BIRTH/SSN`}
        >
          <CobuyerSsnAndBirthdate />
        </LEAccordionItem>
      </LEAccordion>
      <ButtonWrapper>
        <BackButton />
      </ButtonWrapper>
      <LoanAppDevToolbar />
    </LEAccordionContainer>
  );
};

export default CobuyerLoanApp;
