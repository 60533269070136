import { gql } from '@apollo/client';

export const getTTJurisdiction = gql`
  query getTTJurisdiction(
    $state: StateAbbreviation!
    $types: [TTProductType!]!
    $conditions: TTConditionFilterInput
  ) {
    jurisdiction(state: $state) {
      id
      hasRegistrationOnly
      hasTitleOnly
      hasTitleAndRegistration
      allowPlateTransfer
      requireWalkIn
      products(filter: { types: $types }) {
        count
        pageInfo {
          startCursor
          endCursor
        }
        items {
          type
          validations {
            name
            description
          }
          documents(conditions: $conditions) {
            count
            items {
              supportsImageExtraction
              productId
              documentTypeId
              type {
                name
                parameters {
                  name
                  description
                  type
                }
              }
              conditions {
                description
                variables
              }
              validations {
                name
                description
              }
            }
          }
        }
      }
    }
  }
`;

export const uploadDocument = gql`
  mutation uploadDocument($bytes: String!, $documentType: TTDocumentName!) {
    uploadDocument(bytes: $bytes, documentType: $documentType) {
      documentName
      key
      fields {
        confidence
        name
        value
      }
    }
  }
`;

export const uploadDriversLicense = gql`
  mutation uploadDriversLicense($bytes: String!) {
    uploadDriversLicense(bytes: $bytes) {
      firstName
      middleName
      lastName
      address
      city
      state
      zip
      expirationDate
    }
  }
`;

export const getFees = gql`
  query getFees(
    $state: StateAbbreviation!
    $previouslyTitledState: StateAbbreviation!
    $city: String!
    $county: String!
    $zip: String!
    $vin: String!
    $make: String!
    $model: String!
    $year: Int!
    $vehicleType: TTVehicleType!
    $fuelType: TTFuelType!
    $firstName: String!
    $lastName: String!
    $retailBookValue: Float!
    $bookValue: Float!
    $docFee: Float!
    $payoff: Float!
    $estimatedPayoff: Float!
    $ssn: String!
    $warranty: Float!
    $source: String!
  ) {
    getFees(
      state: $state
      previouslyTitledState: $previouslyTitledState
      city: $city
      county: $county
      zip: $zip
      vin: $vin
      make: $make
      model: $model
      year: $year
      vehicleType: $vehicleType
      fuelType: $fuelType
      firstName: $firstName
      lastName: $lastName
      retailBookValue: $retailBookValue
      bookValue: $bookValue
      docFee: $docFee
      payoff: $payoff
      estimatedPayoff: $estimatedPayoff
      ssn: $ssn
      warranty: $warranty
      source: $source
    ) {
      state
      totalFeeAmount
      registrationTransferFee
      salesTaxRate
      baseTaxAmount
      warrantyTaxAmount
    }
  }
`;

export enum TtValidationName {
  DriversLicenseNameMatch = 'DriversLicenseNameMatch',
  DriversLicenseAddressMatch = 'DriversLicenseAddressMatch',
  DriversLicenseStateMatch = 'DriversLicenseStateMatch',
  InsuranceNameMatch = 'InsuranceNameMatch',
  InsuranceStateMatch = 'InsuranceStateMatch',
  InsuranceNotExpired = 'InsuranceNotExpired',
  InsuranceVinMatch = 'InsuranceVinMatch',
  NotarizationRequired = 'NotarizationRequired',
  CobuyerOnInsurance = 'CobuyerOnInsurance',
  WetSignature = 'WetSignature',
  RegistrationNotExpired = 'RegistrationNotExpired',
}
