import { useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  MagnifyingGlassPurpleGuy,
  OdometerPurpleGuy,
  OkGestureGuy,
  PointingGuy,
} from '../../assets/Images';
import { LEAccordion, LEAccordionItem } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import BackButton from '../../components/ui/buttons/BackButton';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import { LDFlags } from '../../constants/flags';
import { AFFILIATE_ENTRY, MKT_HOME } from '../../constants/urls';
import { useFlag, useStep, useTempInfo } from '../../hooks';
import { CookieKeys, useCookie } from '../../hooks/useCookie';
import { resetLocalStorage } from '../../services/localStorage';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { AFFILIATE_PARAMS } from '../flowEntryPages/utils';
import JustTheBasics from './components/JustTheBasicsItem/Content';
import LooksRightItem from './components/LooksRightItem';
import PersonalInfo from './components/PersonalInfo';
import WhoIsYourLease from './components/WhoIsYourLease';

const GettingStarted: React.FC = () => {
  const history = useHistory();
  const { onToggle: refreshToggle } = useDisclosure();
  const step = useStep();

  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;

  useEffect(() => {
    rudderanalytics.track(RudderEvent.RStackWebStartFlow);
  }, []);

  const showPurplePeople = useFlag(LDFlags.SHOW_PURPLE_PEOPLE);
  const gettingStartedHeader = useFlag(LDFlags.GETTING_STARTED_HEADER);

  const { info, infoLoading } = useTempInfo();

  useEffect(() => {
    if (!infoLoading && info?.data && !info.data.vin) {
      if (info.data.affiliate_external_id) {
        history.replace(
          `${AFFILIATE_ENTRY}?${AFFILIATE_PARAMS.AFFILIATE_EXTERNAL_ID}=${info.data.affiliate_external_id}`,
        );
      } else {
        resetLocalStorage({ removeGuid: true });
        history.replace(MKT_HOME);
      }
    }
  }, [info, infoLoading]);

  if (infoLoading) {
    return null;
  }

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>
        {gettingStartedHeader ? 'Getting Started' : 'We found your car!'}
      </LEAccordionHeader>
      <LEAccordion name={ACCORDION_NAME.VEHICLE_ACCORDION} minutes={1}>
        <LEAccordionItem
          accordionKey="vehicle"
          title="DOES THIS LOOK RIGHT?"
          imgSrc={showPurplePeople && MagnifyingGlassPurpleGuy}
        >
          <LooksRightItem />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="odoZipColor"
          title="MILEAGE & COLOR"
          imgSrc={showPurplePeople && OdometerPurpleGuy}
        >
          <JustTheBasics refreshToggle={refreshToggle} />
        </LEAccordionItem>
        <LEAccordionItem
          accordionKey="lienholder"
          title="WHO IS YOUR LEASE THROUGH?"
          imgSrc={showPurplePeople && PointingGuy}
          contentWrapperProps={{ zIndex: 1 }}
        >
          <WhoIsYourLease />
        </LEAccordionItem>
        {!isAuthenticated && (
          <LEAccordionItem
            accordionKey="personalInfo"
            title="STAY INFORMED ON YOUR OFFER"
            imgSrc={showPurplePeople && OkGestureGuy}
          >
            <PersonalInfo />
          </LEAccordionItem>
        )}
      </LEAccordion>
      <ButtonWrapper>
        <BackButton zIndex={0} onClick={() => step.movePrevious({ redirect: true })} />
      </ButtonWrapper>
    </LEAccordionContainer>
  );
};

export default GettingStarted;
