import { NOT_AVAILABLE_LIENHOLDERS_PER_STATE } from '../../../../constants/lienholders';
import { Maybe, StateAbbreviation } from '../../../../gql/generated/graphql';

export const lienholderNotAvailableInState = (
  state: Maybe<string> | null,
  lienholderSlug: Maybe<string>,
) => {
  if (!state || !lienholderSlug) {
    return false;
  }

  return NOT_AVAILABLE_LIENHOLDERS_PER_STATE[state as StateAbbreviation]?.includes(lienholderSlug);
};
