import { useEffect } from 'react';

import { LEAccordion } from '../../components/LEAccordion';
import LEAccordionHeader from '../../components/LEAccordion/LEAccordionHeader';
import LEAccordionContainer from '../../components/LEAccordion/LeAccordionContainer';
import { ACCORDION_NAME } from '../../components/LEAccordion/utils';
import BackButton from '../../components/ui/buttons/BackButton';
import { ButtonWrapper } from '../../components/ui/buttons/ButtonWrapper';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import PayoffAmountAndInstructions from './PayoffAmountAndInstructions';

const PayoffInstructions = () => {
  useEffect(() => {
    rudderanalytics.track(RudderEvent.RStackWebEstimate);
  }, []);

  return (
    <LEAccordionContainer>
      <LEAccordionHeader>Your Payoff Amount</LEAccordionHeader>
      <LEAccordion name={ACCORDION_NAME.PAYOFF_ACCORDION} minutes={5}>
        <PayoffAmountAndInstructions />
      </LEAccordion>
      <ButtonWrapper>
        <BackButton />
      </ButtonWrapper>
    </LEAccordionContainer>
  );
};

export default PayoffInstructions;
