import { StateAbbreviation } from '../gql/generated/graphql';

export const OtherLienholder = 'Other';
export const OtherLienholderSlug = OtherLienholder.toLowerCase();

const nissanFinanceSlug = 'nissan_finance';
const teslaFinanceSlug = 'tesla_finance';

export const NOT_AVAILABLE_LIENHOLDERS_PER_STATE: Partial<Record<StateAbbreviation, string[]>> = {
  [StateAbbreviation.Hi]: [nissanFinanceSlug],
  [StateAbbreviation.Ia]: [teslaFinanceSlug],
  [StateAbbreviation.La]: [teslaFinanceSlug],
};
