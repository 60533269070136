import { Button, Center, Flex, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import { Form, Formik, FormikHelpers } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { NoFeeGuarantee } from '../../../../assets/Images';
import { useLEAccordionItemContext } from '../../../../components/LEAccordion/LEAccordionItem/useLEAccordionItem';
import Input from '../../../../components/formComponents/Input';
import NumberInput from '../../../../components/formComponents/NumberInput';
import PrimaryButton from '../../../../components/ui/buttons/PrimaryButton';
import { LDFlags } from '../../../../constants/flags';
import { VEHICLE_COLORS } from '../../../../constants/vehicleColors';
import {
  useCalculateOptionsLazyQuery,
  useKbbValuesLazyQuery,
  useTemporaryInfoUpdateMutation,
} from '../../../../gql/generated/graphql';
import { useFlag } from '../../../../hooks';
import { evaluateFlag } from '../../../../hooks/useFlag';
import useImageLoader from '../../../../hooks/useImageLoader';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import {
  endingInZeroMileageWarningApplicable,
  overMileageWarningApplicable,
} from '../../../../utils/mileageWarnings';
import { customerAddressValidation } from '../../../../utils/validation/customerInformation';
import { mileageValidation } from '../../../../utils/validation/mileage';
import ColorPicker from './components/ColorPicker';
import MileageWarning from './components/MileageWarning';
import MileageWarningModal from './components/MileageWarningModal';
import { useZip } from './useZip';

interface FormikValues {
  mileage: string;
  zip: string;
  city: string;
  county: string;
  state: string;
  color: VEHICLE_COLORS;
}

const Content = ({ refreshToggle }: { refreshToggle: () => void }) => {
  const showPurplePeople = useFlag(LDFlags.SHOW_PURPLE_PEOPLE);
  const odometerZerosWarningEnabled = useFlag(LDFlags.ODOMETER_ZEROS_WARNING);
  const cityAndCountyValidationEnabled = useFlag(LDFlags.CITY_AND_COUNTY_VALIDATION);
  const { updateState, index, setOpenAccordionIndex, isExpanded } = useLEAccordionItemContext();
  const { info } = useTempInfo('network-only', isExpanded);
  const { isCityCountyNeeded, handleZip, CityCounty } = useZip<FormikValues>(
    cityAndCountyValidationEnabled,
  );
  const ldClient = useLDClient();

  const [calculateOptions] = useCalculateOptionsLazyQuery({ fetchPolicy: 'network-only' });
  const [getKbbValues] = useKbbValuesLazyQuery();
  const [updateTemporaryInfo] = useTemporaryInfoUpdateMutation();

  const [mileageWarningModalOpen, setMileageWarningModalOpen] = useState(false);
  const [showOverMileageWarningNotice, setShowOverMileageWarningNotice] = useState(false);
  const [showingErrors, setShowingErrors] = useState<boolean>(false);
  const [inputMileage, setInputMileage] = useState<number>(0);
  const [mileageWarnings, setMileageWarnings] = useState<{
    endingInZero: boolean;
    overMileage: boolean;
  }>({
    endingInZero: false,
    overMileage: false,
  });
  const { endingInZero, overMileage } = mileageWarnings;

  const urls = useMemo(() => [NoFeeGuarantee], []);
  useImageLoader(urls);

  const validateMileage = () => {
    if ((endingInZero || overMileage) && !showingErrors) {
      const mileageWarningModalEnabled = evaluateFlag(
        ldClient,
        LDFlags.MILEAGE_WARNING_MODAL_VARIATION,
      );
      setShowingErrors(true);
      setShowOverMileageWarningNotice(mileageWarningModalEnabled && overMileage);

      updateState({ status: 'warn' });
    }
  };

  const handleNext = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    if (!info?.data) {
      return;
    }
    updateState({ status: 'loading' });

    const mileage = Number(values.mileage);

    const response = await calculateOptions({
      variables: {
        // vehicleId can be undefined/null if the kbbValues query failed find the vehicle.
        // This is most common with brand new vehicles and Volvos
        vehicleId: info.data.kbb_vehicle_id as number,
        allKbbVehicleOptions: info.data.kbb_all_options,
        vin: info.data.vin ?? '',
        color: values.color,
      },
    });

    const selectedOptions = response?.data?.calculateOptions ?? [];
    const selectedOptionIds = selectedOptions.map((option) => Number(option?.vehicleOptionId));

    // KBB Stuff
    const { data: valuesData } = await getKbbValues({
      variables: {
        data: {
          mileage,
          allKbbVehicleOptions: info.data.kbb_all_options,
          vehicleId: info.data.kbb_vehicle_id,
          vehicleOptionIds: selectedOptionIds,
        },
      },
    });

    const zipValues = await handleZip(values, helpers);

    if (!zipValues) {
      updateState({ status: 'warn' });
      return;
    }
    const { city, county, state, zip } = zipValues;

    const data = {
      retail_book_value: valuesData?.kbbValues?.retail,
      kbb_retail_mileage_adjustment: valuesData?.kbbValues?.retailMileageAdjustment,
      kbb_retail_option_adjustment: valuesData?.kbbValues?.retailOptionAdjustment,
      book_value: valuesData?.kbbValues?.lending,
      kbb_lending_mileage_adjustment: valuesData?.kbbValues?.lendingMileageAdjustment,
      kbb_lending_option_adjustment: valuesData?.kbbValues?.lendingOptionAdjustment,
      kbb_valuation_date: valuesData?.kbbValues?.valuationDate,
      kbb_vehicle_name: info.data.kbb_vehicle_name,
      kbb_trim_name: info.data.kbb_trim_name,
      kbb_selected_options: valuesData?.kbbValues?.kbbSelectedOptions,
      zip,
      city: city.trim(),
      county: county.trim(),
      state,
      mileage: Number(values.mileage),
      color: values.color,
    };

    refreshToggle();

    await updateTemporaryInfo({
      variables: {
        info: {
          id: info.id,
          data,
        },
      },
    });

    updateState({ status: 'complete' });
    ldClient?.track('vehicle-options-selected', {});
    setOpenAccordionIndex(index + 1);
  };

  useEffect(() => {
    if (!inputMileage) return;

    setMileageWarnings({
      endingInZero:
        odometerZerosWarningEnabled && endingInZeroMileageWarningApplicable(inputMileage),
      overMileage: overMileageWarningApplicable(inputMileage),
    });
  }, [inputMileage]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleNext}
      initialValues={{
        mileage: String(info?.data.mileage || ''),
        zip: info?.data.zip || '',
        city: info?.data.city || '',
        county: info?.data.county || '',
        state: info?.data.state || '',
        color: (info?.data.color || '') as VEHICLE_COLORS,
      }}
      validationSchema={Yup.object({
        zip: customerAddressValidation.zip,
        mileage: mileageValidation,
        color: Yup.string().required('Color is required'),
        ...(isCityCountyNeeded
          ? {
              city: customerAddressValidation.city,
              county: customerAddressValidation.county,
              state: customerAddressValidation.state,
            }
          : {}),
      })}
    >
      {({ isSubmitting, values }) => {
        const mileage = Number(values.mileage);

        return (
          <>
            <Form>
              <NumberInput
                mt={showPurplePeople ? '45px' : '10px'}
                name="mileage"
                placeholder="Mileage"
                label="Current Odometer"
                showThousandSeparator
                isWhole
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInputMileage(Number(event.target.value.replace(',', '')));
                  setShowingErrors(false);
                  setShowOverMileageWarningNotice(false);
                  updateState({ status: 'none' });
                }}
                onBlur={validateMileage}
              />
              {showOverMileageWarningNotice && (
                <VStack mt={2} alignItems="flex-start" fontSize="12px">
                  <Flex color="red" alignItems="center">
                    <Icon mr={2} as={FaExclamationTriangle} />
                    <Text>MILEAGE ALERT</Text>
                  </Flex>
                  <Button onClick={() => setMileageWarningModalOpen(true)} variant="link">
                    See How This Affects Your Deal
                  </Button>
                </VStack>
              )}
              <Stack direction="row" my="15px">
                <Input
                  name="zip"
                  placeholder="ZIP Code"
                  label="Zip Code"
                  alignSelf="flex-start"
                  _input={{
                    inputMode: 'numeric',
                    maxLength: 5,
                  }}
                />
                <ColorPicker />
              </Stack>
              {showingErrors && (
                <MileageWarning mileage={mileage} mileageWarnings={mileageWarnings} />
              )}
              {CityCounty}
              <Center mt="30px">
                <PrimaryButton type="submit" isLoading={isSubmitting}>
                  NEXT
                </PrimaryButton>
              </Center>
            </Form>
            <MileageWarningModal
              mileage={mileage}
              isOpen={mileageWarningModalOpen}
              onClose={() => setMileageWarningModalOpen(false)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Content;
