import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Container,
  Image,
  Link,
  ListItem,
  Skeleton,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { CouchSleep } from '../../assets/Images';
import Description from '../../components/Description';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Label from '../../components/formComponents/Label';
import { OtherLienholderSlug } from '../../constants/lienholders';
import { ttJurisdictionDefaultConditions } from '../../constants/taterTitle';
import {
  StateAbbreviation,
  TtProductType,
  useGetTtJurisdictionLazyQuery,
} from '../../gql/generated/graphql';
import { lienholderQuery } from '../../gql/prs/lienholderGql';
import { useTempInfo } from '../../hooks';
import { resetLocalStorage } from '../../services/localStorage';
import { getAddressValue } from '../../utils/address';
import getSearchPhoneNumbers from '../../utils/getSearchPhoneNumbers';
import { RudderEvent, rudderanalytics } from '../../utils/rudderstack';
import { ResidenceFormValues } from '../LoanApp/utils';
import FaqsWrapper from './FaqsWrapper';
import InTheMeantime from './InTheMeantime';

interface NicelyDoneProps {
  payoffFailure?: boolean;
}

const Skeletons = () => (
  <VStack w="full">
    <Skeleton height="40px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="40px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
    <Skeleton height="20px" w="full" />
  </VStack>
);

const NicelyDone = ({ payoffFailure = false }: NicelyDoneProps) => {
  const { info } = useTempInfo();
  const phoneNumber = getSearchPhoneNumbers();
  const [ttJurisdictionQuery, { loading: jurisdictionLoading }] = useGetTtJurisdictionLazyQuery();
  const [getLienholderInfo, { loading: lienholderInfoLoading }] = useLazyQuery(lienholderQuery);

  const [warnings, setWarnings] = useState({
    directPay: false,
    requireWalkIn: false,
    doubleTax: false,
  });

  const tempInfoData = info?.data;

  const checkWarnings = async ({
    address_line,
    address_line_2,
    city,
    state,
    zip,
    moved_states,
  }: Partial<ResidenceFormValues>) => {
    const address = getAddressValue({
      address_line,
      address_line_2,
      city,
      state,
      zip: zip?.toString(),
    });
    const lienholderSlug = info?.data?.lienholder_slug;

    let directPay = false;
    let doubleTax = false;
    if (lienholderSlug !== OtherLienholderSlug) {
      const response = await getLienholderInfo({
        fetchPolicy: 'network-only',
        variables: {
          state,
          slug: lienholderSlug,
        },
      });

      const lienholderInfo = response?.data?.lienholder;
      directPay = lienholderInfo?.requirements_to_payoff_lease?.direct_pay?.value;
      doubleTax = lienholderInfo?.double_tax;

      if (doubleTax) {
        rudderanalytics.track(RudderEvent.DoubleTaxModalShown);
      }
    }

    const jurisdiction = await ttJurisdictionQuery({
      variables: {
        state: state as StateAbbreviation,
        types: [TtProductType.Registration, TtProductType.Title],
        conditions: {
          ...ttJurisdictionDefaultConditions(state as StateAbbreviation),
          lienholder: lienholderSlug,
          hasCobuyer: false,
          driversLicenseAddress: address,
          currentAddress: address,
          vehicleAge: new Date().getFullYear() - parseInt(info?.data?.year || '0', 10),
          transferringPlates: false,
          driversLicenseState: state,
          movedStateDuringLease: moved_states === true.toString(),
          jurisdictionState: state,
        },
      },
    });

    const requireWalkIn = jurisdiction.data?.jurisdiction?.requireWalkIn ?? false;

    setWarnings((prevState) => ({
      ...prevState,
      directPay,
      requireWalkIn,
      doubleTax,
    }));

    resetLocalStorage({ removeGuid: true, removeAccessToken: true });
  };

  useEffect(() => {
    if (tempInfoData) {
      checkWarnings({
        address_line: tempInfoData.address_line,
        address_line_2: tempInfoData?.address_line_2,
        city: tempInfoData?.city,
        state: tempInfoData?.state,
        zip: tempInfoData?.zip,
        moved_states: tempInfoData?.moved_states,
      } as Partial<ResidenceFormValues>);
    }
  }, [tempInfoData]);

  const anyWarning = Object.values(warnings).some((warning) => warning);
  const loading = jurisdictionLoading || lienholderInfoLoading;

  return (
    <Container textAlign="left">
      <TitleContainer>
        <Title maxW={{ base: '256px', md: 'none' }}>
          {payoffFailure ? 'We Were Unable to Find Your Payoff' : 'Nicely Done!'}
        </Title>
      </TitleContainer>
      {!payoffFailure && !anyWarning && (
        <Subtitle
          w={{ base: '90%', lg: '700px' }}
          m={{ base: '0 auto 20px', lg: '0 auto' }}
          textAlign="center"
        >
          We hope that was easier than when the teacher accidentally leaves the map pulled down for
          a 'test on the state capitals.
        </Subtitle>
      )}
      <Box overflow="hidden" w="100%" display={{ base: 'block', md: 'none' }}>
        <Image
          minW="550px"
          ml="-35px"
          src={CouchSleep}
          alt="The Lease End Purple Guy on the couch asleep with his cat."
        />
        <Box
          borderTop="3px solid"
          borderTopColor="midnightBlueDark"
          bgColor="grayBackground"
          h="10vh"
          w="150vw"
          m="-70px -50vw 0px"
          zIndex="-1"
        />
      </Box>
      <Box
        bgColor={{ base: 'grayBackground', lg: 'white' }}
        w={{ base: '100%', lg: '615px' }}
        m={{ base: '0 auto', lg: '30px auto' }}
        p="30px 20px"
        h="auto"
        textAlign="left"
      >
        {payoffFailure ? (
          <Description m={{ base: '10px auto', lg: '15px auto' }}>
            Our advisors couldn’t validate your payoff amount or good-through date with the
            information provided. We’ll be in contact soon to resolve this.
          </Description>
        ) : loading ? (
          <Skeletons />
        ) : (
          <>
            <Label fontSize={{ base: 'unset', lg: '20px' }}>WHAT'S NEXT</Label>
            <Description m={{ base: '10px auto', lg: '15px auto' }} as="div">
              {anyWarning ? (
                <>
                  Sit back—we’re securing the best loan for you! Once approved, we’ll call to review
                  your lease payoff, loan details, and next steps.
                  <br />
                  <br />
                  This usually takes 5-30 minutes during business hours. If you applied after hours,
                  we’ll start first thing in the morning.
                  <Label my={{ base: '10px', lg: '15px' }} fontSize={{ base: 'unset', lg: '20px' }}>
                    FYI
                  </Label>
                  Some states and lienholders make things tricky, but we’ll help you navigate every
                  step.
                  <UnorderedList mt={{ base: '10px', lg: '15px' }}>
                    {warnings.directPay && (
                      <ListItem>
                        Your lienholder or state requires you{' '}
                        <Text as="span" fontWeight="bold">
                          visit the dealership in person
                        </Text>{' '}
                        to complete your lease buyout. We’ll prepare everything—you just need to
                        make the final visit.
                      </ListItem>
                    )}
                    {warnings.doubleTax && (
                      <ListItem>
                        We need to confirm that the name(s) on your lease match who is buying the
                        car. If they don’t, the payoff amount may change.
                      </ListItem>
                    )}
                    {warnings.requireWalkIn && (
                      <ListItem>
                        Your state requires that you complete your titling process in person. We’ll
                        prepare everything—you just need to take it in.
                      </ListItem>
                    )}
                  </UnorderedList>
                </>
              ) : (
                <>
                  Now you can relax knowing that we are working to find the best loan available.
                  Once we have an approval from the bank, we will call you back and go over the
                  payoff of your lease, the details of your new loan, and next steps regarding
                  paperwork & registration.
                  <br />
                  <br />
                  It takes anywhere from 5-30 minutes (during business hours) to get responses from
                  our lending partners and get back to you. If we receive your application after
                  business hours we will start working on it first thing in the morning.
                  <br />
                  <br />
                  If you have any questions at all feel free to call us at{' '}
                  <Link fontWeight="bold" display="inline" href={`tel:+1-${phoneNumber}`}>
                    {phoneNumber}
                  </Link>
                  .
                </>
              )}
            </Description>
          </>
        )}
      </Box>
      <InTheMeantime />
      <FaqsWrapper />
      <Box display={{ base: 'none', md: 'block' }}>
        <Image
          w={{ base: '650px', lg: '1000px' }}
          h="auto"
          m="0 auto"
          src={CouchSleep}
          alt="The Lease End Purple Guy on the couch asleep with his cat."
        />
        <Box
          borderTop="3px solid"
          borderTopColor="midnightBlueDark"
          bgColor="grayBackground"
          h={{ base: '50vh', lg: '20vh' }}
          w="147vw"
          m={{ base: '-71px -50vw 0px', lg: '-110px -50vw 0px' }}
          zIndex="-1"
        />
      </Box>
      ;
    </Container>
  );
};

export default NicelyDone;
