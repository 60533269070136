export enum CustomerInfo {
  VIN = 'VIN',
  ZIP = 'ZIP',
  DOB = 'DOB',
  SSN = 'SSN',
  SSN_LAST_6 = 'SSN_LAST_6',
  SSN_LAST_4 = 'SSN_LAST_4',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  CURRENT_ODOMETER = 'CURRENT_ODOMETER',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export enum InstructionType {
  MOBILE_APP = 'MOBILE_APP',
  WEBSITE = 'WEBSITE',
  PHONE = 'PHONE',
}

export type Option = CustomerInfo[];
export type Options = Option[];
export type PayoffMethod = { [key: string]: { value: string } | string };
export type PayoffMethods = { [key: string]: PayoffMethod };

export enum InquiryType {
  ACC = 'ACC',
  SSN = 'SSN',
  VIN = 'VIN',
}

export type Lienholder = {
  id: string;
  slug: string;
  display_name: string;
  logo_url: string;
  probableLienholder: boolean;
  enabled: boolean;
};

export type InstructionSet = {
  instructions: Instruction[];
  phone_number: string;
  note: string;
  intro: string;
  type: string;
};

export type Instruction = {
  text: string;
  details: string[];
};
