import { Flex, Text } from '@chakra-ui/react';
import { FormikHelpers, FormikValues } from 'formik';
import { useMemo, useState } from 'react';

import FormikEffect from '../../../../components/formComponents/FormikEffect';
import Input from '../../../../components/formComponents/Input';
import Select from '../../../../components/formComponents/Select';
import { statesArray } from '../../../../constants/states';
import { useAddressDetailLazyQuery } from '../../../../gql/generated/graphql';
import { useTempInfo } from '../../../../hooks/useTempInfo';
import { decodeZip } from '../../../../utils/address';

interface ZipOpts {
  city: string;
  county: string;
  state: string;
  zip: string;
}

export const useZip = <T extends ZipOpts>(cityAndCountyValidationEnabled = true) => {
  const { info } = useTempInfo();

  const [isCityCountyNeeded, setIsCityCountyNeeded] = useState(false);
  const [lastCheckedZip, setLastCheckedZip] = useState('');
  const [addressDetailLazyQuery, { data: addressData }] = useAddressDetailLazyQuery();

  const handleZip = async (values: T, helpers: FormikHelpers<T>) => {
    const { zip } = values;
    let { city, county, state } = values;

    const isZipUnchecked = lastCheckedZip !== '' && zip !== lastCheckedZip;
    const isTempInfoZipIncomplete =
      zip !== info?.data?.zip || !info?.data?.city || !info?.data?.county || !info?.data?.state;

    if (isZipUnchecked || (lastCheckedZip === '' && isTempInfoZipIncomplete)) {
      setLastCheckedZip(zip);
      const decodedZip = await decodeZip({ zip, addressDetailLazyQuery });
      city = decodedZip.city;
      county = decodedZip.county;
      state = decodedZip.state;
      helpers.setFieldValue('city', city);
      helpers.setFieldValue('county', county);
      helpers.setFieldValue('state', state);

      if (state === '' || (cityAndCountyValidationEnabled && (city === '' || county === ''))) {
        setIsCityCountyNeeded(true);
        return false;
      }
    }

    return { city, county, state, zip };
  };

  const CityCounty = useMemo(() => {
    if (addressData?.addressDetail?.state && !cityAndCountyValidationEnabled) {
      return null;
    }

    return (
      <>
        <FormikEffect<FormikValues>
          onChange={({ values }) => {
            if (isCityCountyNeeded && lastCheckedZip !== values.zip) {
              setIsCityCountyNeeded(false);
            }
          }}
        />
        {isCityCountyNeeded && (
          <Flex direction="column" alignItems="center" pt="15px">
            <Text color="leaseEndRed" fontSize="16px" fontWeight="semibold">
              Your City and County
            </Text>
            <Text fontSize="14px" fontWeight="normal" textAlign="center">
              We couldn't find an exact match based on your zip code. Please confirm your city
              and/or county.
            </Text>
            <Flex w="100%" mt="20px" gap="15px">
              <Input name="city" label="City" placeholder="City Name" />
              <Input name="county" label="County" placeholder="County Name" />
              {!addressData?.addressDetail?.state && (
                <Select
                  id="stateSelect"
                  name="state"
                  placeholder="State"
                  label="State"
                  options={statesArray}
                />
              )}
            </Flex>
          </Flex>
        )}
      </>
    );
  }, [addressData?.addressDetail?.state, isCityCountyNeeded, lastCheckedZip]);

  return { isCityCountyNeeded, handleZip, CityCounty };
};
