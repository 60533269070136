/* eslint-disable react/no-array-index-key */
import { LDFlags } from '../../../constants/flags';
import { InstructionSet } from '../../../gql/prs/types';
import { useFlag } from '../../../hooks';
import Label from '../../formComponents/Label';
import Instructions from './Instructions';

const AppInstructions = ({
  lienholderName,
  appInstructions,
}: {
  lienholderName: string;
  appInstructions: InstructionSet[];
}) => {
  const showGoodThroughDate = !useFlag(LDFlags.HIDE_GOOD_THROUGH_DATE);

  return (
    <>
      <Label fontSize={{ md: '16px' }}>
        Get your payoff amount {showGoodThroughDate ? 'and good-through date' : ''} by opening the{' '}
        {lienholderName} mobile app.
      </Label>
      {appInstructions.map((appInstruction, i) => (
        <Instructions key={`app-instruction-${i}`} instructions={appInstruction.instructions} />
      ))}
    </>
  );
};

export default AppInstructions;
