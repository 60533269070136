export const DEALERSHIP_MILEAGE_ALLOWANCE = 36_000; // miles

export const getEstimatedOverageFee = (mileage: number): number =>
  (mileage - DEALERSHIP_MILEAGE_ALLOWANCE) * 0.2;

export const endingInZeroMileageWarningApplicable = (mileage: number | string): boolean =>
  mileage.toString().endsWith('00');

export const overMileageWarningApplicable = (mileage: number): boolean =>
  mileage > DEALERSHIP_MILEAGE_ALLOWANCE && getEstimatedOverageFee(mileage) >= 150;
